export class TrackRecords {
  constructor() {
    this.trackRecordsScroll = [...document.querySelectorAll(".track-records")];

    if (this.trackRecordsScroll.length > 0) {
      this.btnPrev = document.querySelector(".track-records__arrow-left");
      this.btnNext = document.querySelector(".track-records__arrow-right");
      this.wrapper = document.querySelector(".track-records__wrapper");
      this.blocks = document.querySelectorAll(".track-records__item");
      this.distance = this.blocks[0].offsetWidth + (window.innerWidth < 768 ? 16 : 32);
      this.index = 1;
      this.init();
    }
  }

  init() {
    this.displayArrows();

    this.btnPrev.addEventListener("click", () => {
      this.wrapper.scrollLeft -= this.distance;
      this.index -= 1;
      this.displayArrows();
    });

    this.btnNext.addEventListener("click", () => {
      this.wrapper.scrollLeft += this.distance;
      this.index += 1;
      this.displayArrows();
    });
  }

  displayArrows() {
    this.btnPrev.style.display = "block";
    this.btnNext.style.display = "block";

    if (this.index == 1) {
      this.btnPrev.style.display = "none";
    }

    if (this.index == this.blocks.length) {
      this.btnNext.style.display = "none";
    }
  }
}
