"use strict";

import { SinglePeople } from "./includes/single-people";
import { TrackRecords } from "./includes/track-records";
import { Slider } from "./includes/slider";
import AOS from "aos";
import { TeamFilter } from "./includes/team-filter";

document.addEventListener("DOMContentLoaded", () => {
  const menuTriggers = document.querySelectorAll(".menu-trigger");
  const menuContainer = document.querySelector(".primary-navigation");
  const navContainer = document.querySelector(".navigation-sidebar");

  menuTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      navContainer.classList.toggle("is-open");
      menuContainer.classList.toggle("is-open");
    });
  });

  AOS.init({
    duration: 1200,
    easing: "ease-in-out",
    once: true,
    mirror: false,
  });

  new SinglePeople();
  new Slider();
  new TrackRecords();
  new TeamFilter();

  const careerItems = [...document.querySelectorAll(".careers-selection__item, .careers-header__slider-item")];

  careerItems.forEach((item) => {
    item.addEventListener("mouseover", (e) => {
      careerItems.forEach((item) => {
        item.classList.remove("w-200");
      });

      item.classList.add("w-200");
    });
  });
});

jQuery(window).scroll(function () {
  if (jQuery(this).scrollTop() > 150) {
    jQuery("header").addClass("fixed");
  } else {
    jQuery("header").removeClass("fixed");
  }
});
