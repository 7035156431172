export class Slider {
  constructor() {
    this.tracks = document.querySelectorAll(".careers-header__slider-track");
    if (this.tracks.length > 0) {
      this.init();
    }
  }

  init() {
    this.btnNext = document.querySelectorAll(".careers-header__slider-nav--next");
    this.btnPrev = document.querySelectorAll(".careers-header__slider-nav--prev");
    this.numberSlides = this.tracks[0].getAttribute("data-slides");
    this.activeSlide = 1;

    this.showButtons();

    this.btnNext.forEach((btn) => {
      btn.addEventListener("click", () => {
        if (this.activeSlide > 0 && this.activeSlide < this.numberSlides) {
          this.activeSlide += 1;
          this.changeSlide();
        }
      });
    });

    this.btnPrev.forEach((btn) => {
      btn.addEventListener("click", () => {
        if (this.activeSlide <= this.numberSlides && this.activeSlide > 1) {
          this.activeSlide -= 1;
          this.changeSlide();
        }
      });
    });
  }

  changeSlide() {
    let trackOffset = this.activeSlide - 1;
    this.tracks.forEach((track) => {
      track.style.left = `-${trackOffset}00%`;
    });
    this.showButtons();
  }

  showButtons() {
    this.btnPrev.forEach((btn) => {
      btn.style.visibility = "visible";
    });
    this.btnNext.forEach((btn) => {
      btn.style.visibility = "visible";
    });

    if (this.activeSlide == 1) {
      this.btnPrev.forEach((btn) => {
        btn.style.visibility = "hidden";
      });
    }
    if (this.activeSlide == this.numberSlides) {
      this.btnNext.forEach((btn) => {
        btn.style.visibility = "hidden";
      });
    }
  }
}
