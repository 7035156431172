export class SinglePeople {
  constructor() {
    this.toggleContentBlocks();
  }

  toggleContentBlocks() {
    const toggles = [...document.querySelectorAll(".single-people__toggle")];

    if (toggles.length > 0) {
      toggles.forEach((toggle) => {
        toggle.addEventListener("click", (e) => {
          e.target.classList.toggle("is-open");

          e.target.nextElementSibling.classList.toggle("is-open");
        });
      });
    }
  }
}
