export class TeamFilter {
  constructor() {
    this.input = document.getElementById("searchTeam");
    this.team = document.querySelectorAll(".team-overview__person");

    if (this.input) {
      this.init();
    }
  }

  init() {
    this.input.addEventListener("keyup", () => {
      let filter = this.input.value.toUpperCase();

      this.team.forEach((person) => {
        let search = person.querySelector(".team-overlay__item-name").textContent || person.querySelector(".team-overlay__item-name").innerText;
        if (search.toUpperCase().indexOf(filter) > -1) {
          person.style.display = "block";
        } else {
          person.style.display = "none";
        }
      });
    });
  }
}
